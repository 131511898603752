import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-contact-error-modal',
  templateUrl: './contact-error-modal.component.html',
  styleUrls: ['./contact-error-modal.component.scss']
})
export class ContactErrorModalComponent implements OnInit {
  @Output() closeModalEmitter = new EventEmitter();
  @Input() role: string;
  @Input() comments: string;
  mailTo = 'mailto:ryan-smith@ryan-smith.dev?body=';



  constructor() { }

  ngOnInit() {
    if (this.role) {
      this.mailTo = `${ this.mailTo }Role: ${ this.role } `;
    }
    if (this.comments) {
      this.mailTo = this.mailTo + this.comments;
    }
  }

  closeModal() {
    this.closeModalEmitter.emit(false);
  }

}
