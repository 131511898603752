import { Component, OnInit } from '@angular/core';
import {skillItems} from '../../assets/content/skill-items';
import { SkillSet } from '../models/skill-set';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  skillSets: SkillSet[] = skillItems;
  et = skillItems;

  constructor() { }

  ngOnInit() {
  }

}
