import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-me',
  templateUrl: './contact-me.component.html',
  styleUrls: ['./contact-me.component.scss']
})
export class ContactMeComponent implements OnInit {
  private static timeout = 3000;
  private pendingSubmit = false;
  private displayErrorModal = false;
  private role: string;
  private form = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', Validators.required],
    role: [null, Validators.required],
    otherRole: [''],
    comments: ['']
  });

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.form.controls.role.valueChanges.subscribe(changes => {
      if (changes === 'other') {
        this.form.controls.otherRole.setValidators(Validators.required);
        this.form.controls.otherRole.updateValueAndValidity();
      } else {
        this.form.controls.otherRole.reset();
        this.form.controls.otherRole.clearValidators();
        this.form.controls.otherRole.markAsPristine();
        this.form.controls.otherRole.updateValueAndValidity();
      }
    });
  }


  submitContactForm() {
    this.pendingSubmit = true;
    this.role = this.form.value.role === 'other' ? this.form.value.otherRole : this.form.value.role;
    setTimeout(() => {
      this.toggleErrorModal(true);
      this.pendingSubmit = false;
      }, ContactMeComponent.timeout);
  }

  resetForm() {
    this.form.reset();
    this.form.controls.otherRole.reset();
    this.form.controls.otherRole.clearValidators();
    this.form.controls.otherRole.markAsPristine();
    this.form.controls.otherRole.updateValueAndValidity();
  }

  toggleErrorModal(displayModal: boolean) {
    if (!displayModal) {
      this.resetForm();
    }
    this.displayErrorModal = displayModal;
  }
}
