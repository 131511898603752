import { SkillSet } from '../../app/models/skill-set';

export const skillItems: SkillSet[] = [
  {
    header: 'Languages',
    skills: [
      'HTML',
      'CSS',
      'JavaScript',
      'Java',
      'Protractor',
      'Oracle SQL',
      'PostgreSQL'
    ]
  },
  {
    header: 'Frameworks',
    skills: [
      'Angular',
      'SASS',
      'SpringBoot',
      'Cucumber'
    ]
  },
  {
    header: 'Tools',
    skills: [
      'GitHub',
      'Rally',
      'Webstorm',
      'IntelliJ',
      'VS Code'
    ]
  }
];
